import { useContext, useEffect } from 'react';
import { copy, removeFields } from '@thd-olt-functional/utils';
import {
  useDataModel,
  params,
  string as stringType,
  arrayOf as arrayType,
  customType
} from '@thd-nucleus/data-sources';
import { CustomerContext } from '../components/CustomerContext';

/*
apollo has some race condition and because this hook is called recursively with ds
useDataModel -> useStore -> useMembershipInformation -> useDataModel
there is functionality in the cache for it to return in loading state for loyaltyMembership
even when its succesfully returned and in cache. As a workaround changing fetchPolicy
to cache-only forces it to pull from cache early.
*/

let hasData = false;
export const useMembershipInformation = ({ customerType }) => {

  const isClient = typeof window !== 'undefined';

  /*
    due to this hook being here it is causing us to have a "Rendered more
    hooks than the last render" error and an FID spike in b2c experiences.
    If we move this below we will not be able to test for exchange customers.
  */
  const {
    membershipInformation: savedMembershipInformation,
    isExchangeCustomer: savedIsExchangeCustomer,
    exchangeCustomerCookies: savedExchangeCustomerCookies
  } = useContext(CustomerContext);

  if (!isClient || (customerType !== 'B2B' && !savedIsExchangeCustomer)) {
    return {
      apiResponse: {
        data: null,
        error: null,
        loading: null,
      },
      customerType,
      isExchangeCustomer: false
    };
  }

  if (savedMembershipInformation) {
    return {
      apiResponse: {
        data: { loyaltyMembership: savedMembershipInformation },
        error: null,
        loading: false,
      },
      customerType,
      isExchangeCustomer: savedIsExchangeCustomer
    };
  }

  const options = {
    ssr: false,
    dataModel: useMembershipInformation.dataModel,
    fetchPolicy: hasData ? 'cache-only' : 'cache-first',
    mounted: true,
    ...(savedIsExchangeCustomer && {
      context: {
        headers: savedExchangeCustomerCookies
      }
    })
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, loading, error } = useDataModel('loyaltyMembership', options);

  let loyaltyData = null;
  if (data) {
    hasData = true;
    const dataClone = copy(data);
    loyaltyData = removeFields(dataClone.data, ['__typename']) || { loyaltyMembership: null };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (loyaltyData && !loading) {
      // eslint-disable-next-line no-unused-expressions
      window.THDCustomer?.default?.setMembershipInformation(loyaltyData.loyaltyMembership);
    }
  }, [loading]);

  // Return if loyaltyMembership Information is provided by query
  return {
    apiResponse: {
      data: loyaltyData,
      error,
      loading,
    },
    customerType,
    isExchangeCustomer: savedIsExchangeCustomer
  };
};

useMembershipInformation.dataModel = {
  loyaltyMembership: params().shape({
    svocID: stringType(),
    programTiers: arrayType(
      customType('ProgramTiers').shape({
        tier: stringType(),
        program: stringType(),
      })
    ),
  }),
};
